import React from "react"
import Layout from "../components/layout"
import Helmet from "react-helmet"
import lighthouse from "../../static/lighthouse.jpg"

export default () => (
    <Layout className="h-full w-full">
        <Helmet>
            <title>daniel stewart - about</title>
            <script src="https://kit.fontawesome.com/4d67d05bdb.js" crossorigin="anonymous"></script>
        </Helmet>        
        <div className="flex flex-row justify-around items-end mb-8 md:mb-24">
            <div className="font-black text-xl">daniel stewart</div>
            <div>about 🕵️</div>
        </div>
        <div className="flex flex-col lg:flex-row">
            <div className="mb-8 md:mb-24 lg:w-1/2">
                <p className="mb-3">I'm daniel. Sometimes I make things. But sometimes I also don't do anything at all.</p>
                <p className="mb-3">I'd like to think that I'm more of a generalist. I like to dabble. I like learning new things, and the feeling that comes along with that.
                    Everyday I find more things that I need to learn. The list is really piling up. That's both exciting and terrifying.
                </p>
                <p className="mb-3">Right now I'm making some pretty cool things, with some even cooler people at <a href="https://www.sidearmsports.com/">SIDEARM Sports</a> as a Full Stack Web Developer. These are definitely 
                    some days I'll look back on and be really grateful for.</p>
                <p className="mb-3">I did the whole computer science thing at 🍊 Syracuse University. Learned some math, some webs, how to search and sort, find shortest paths - that sort of thing.
                    That was pretty cool. Now I just spend most of my time coming up with decent variable names.</p>
                <p>When I'm not doing anything like that, I like to go outside. But not as much as I like to stare at a screen and play games. 
                    It's been like that since I can remember, but I think I turned out okay.
                    Right now I'm playing a whole lot of Quake. Hoping a popular new arena fps comes out in the next few years, but I think that's not happening.
                </p>
            </div>
            <div className="lg:w-1/2"><img className="lg-8 lg:mb-0 md:w-3/4 md:mx-auto" src={lighthouse} /></div>
        </div>
    </Layout>
)
